.small-slider {
  font-family: sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.images-container {
  display: flex;
  max-width: 500px;
  overflow: scroll;
  scroll-behavior: smooth;
  transition: scroll 0.3s ease-in-out;
}

@media screen and (max-width: 778px) {
  .images-container {
    display: flex;
    max-width: 250px;
    overflow: scroll;
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
  }
}

.image {
  max-width: 500px;
  width: 150px;
  height: 250px;
  margin: 5px;
  border-radius: 8px;
}

.nav-btn {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #c3c3c3;
  border-radius: 6px;
  height: 200px;
  width: 30px;
  margin: 5px;
}

.po_item {
  display: block;
  text-align: center;
  margin: 0.5rem auto;
  position: relative;
  /* background: var(--secondary-color); */
  padding: 6px;
  /* border: 1px solid black; */
  transition: 0.3s ease;
  font-size: 0;
}
@media screen and (max-width: 778px) {
  .po_items_ho {
    display: block;
  }
}

.po_item .content {
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(128, 128, 128, 0.7);
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transition: 0.3s ease-in-out;
  opacity: 0;
  border: 1px solid black;
}

.po_item .content {
  opacity: 0;
  transition-delay: 1s;
  transition: 0.3s ease;
  font-size: 25px;
}

.po_item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  opacity: 1;
}

.po_item a {
  color: whitesmoke;
}

.po_item:hover .content {
  opacity: 1;
  color: whitesmoke;
}

.po_item .content button {
  background: rgb(173, 172, 172);
  border: solid 1px whitesmoke;
  padding: 4px 8px;
  text-align: center;
  font-size: 1rem;
}

.po_item .content button:hover {
  text-decoration: none;
}
