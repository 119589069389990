/* google fonts
----------++---------- */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://use.fontawesome.com/releases/v5.7.2/css/all.css");

/* Normalizing the default styles ---------- */
*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}

h1,
h2 {
  line-height: 1.2;
}

a {
  display: inline-block;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: baseline;
}

/* css variables to reuse same color 
----------++---------- */
:root {
  --clr-primary: #2c541d;
  --clr-secondary: #74a84a;

  --clr-white: #fefefe;
  --clr-black: #000000;
}

::selection {
  background-color: #74a84a;
  color: #000000;
}

/* base style  
----------++---------- */
body {
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow-x: hidden;
  background-color: rgb(255, 255, 255); /* rgb(253, 234, 234); */
}

i {
  vertical-align: middle;
}

/* reused styles 
----------++---------- */
.container {
  width: 1180px;
  max-width: 95%;
  margin: 0 auto;
  padding: 0 10px;
}

.links {
  display: flex;
  justify-content: space-evenly;
  gap: 32px;
}

.btn-fill {
  background-color: var(--clr-secondary);
  color: var(--clr-white);
  padding: 10px 48px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s background-color;
}

.upload {
  background-color: var(--clr-secondary);
  color: var(--clr-white);
  padding: 10px 15px;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s background-color;
  max-width: 80%;
}

.links .btn-fill {
  max-width: 80%;
  padding: 10px 15px;
}

.btn-fill:is(:focus, :hover) {
  background-color: var(--clr-primary);
}

.auth {
  position: relative;
  z-index: 100;
}

.button_back {
  position: fixed;
  z-index: 1;
}

/* header section style 
----------++----------  */
header {
  width: 100%;
  height: 100px;
  padding: 16px 0;

  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 4;

  transition-duration: 0.3s;
  transition-property: padding background-color box-shadow;
}

header.sticky {
  padding: 8px 0;
  background-color: #dbdbdb2b;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

header .container {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}

header .logo img {
  width: 200px;
}

header .right,
header nav {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

header nav a,
header nav button {
  color: var(--clr-black);
  padding: 10px;
}

header nav a:is(:focus, :hover),
header nav button:is(:focus, :hover) {
  color: var(--clr-secondary);
}

header .hamb,
.nav-mobile {
  display: none;
}

/* adding internal media queries so that it will be easier to understand for you :) */
@media screen and (max-width: 800px) {
  header .hamb,
  .nav-mobile {
    display: block;
  }

  header .hamb #icon {
    font-size: 2rem;
    cursor: pointer;
  }

  header .nav-desktop {
    display: none;
  }

  .nav-mobile {
    display: grid;
    gap: 24px;
    background-color: #cc7979;
    padding: 20px 20px;
    width: 50%;
    position: fixed;
    right: 0px;
    top: 100px;
    z-index: 5;
    transition: 0.3s right;
  }

  .nav-mobile a,
  .nav-mobile button {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 350;
    letter-spacing: 1px;
    color: var(--clr-white);
    transition: 0.3s color;
  }

  .nav-mobile a:is(:focus, :hover),
  .nav-mobile button:is(:focus, :hover) {
    color: var(--clr-black);
  }

  header nav a,
  header nav button {
    padding: 0px;
  }
}

header button {
  border: none;
  background-color: transparent;
  outline: none;
}

/* home section style 
----------++---------- */
.home {
  width: 100%;
  /* height: 100%; */
  padding-top: 150px;

  background-color: var(--clr-white);
  background-repeat: no-repeat;
  background-position: center top;
}

.home .container {
  text-align: center;
}

.home .title {
  font-size: 3.5rem;
  font-weight: 450;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--clr-primary);
}

.home .subtitle {
  font-family: "Indie Flower", serif;
  font-size: 1.5rem;
  font-weight: 400;
  /* text-transform: uppercase; */
  margin: 0px 0 0px;
}

.home .info {
  font-size: 1.5rem;
  font-weight: 350;
  /* margin: 16px 0 32px; */
}
@media screen and (min-width: 750px) {
  .home {
    padding-top: 100px;
  }
  .home .principal {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .home .subtitle {
    margin-bottom: 20px;
  }
}

.step {
  /* font-family: "Indie Flower", serif; */
  font-size: 1.5rem;
  font-weight: 400;
  /* margin: 16px 0 32px; */
}

/* services section style 
----------++---------- */
.services {
  width: 100%;
  height: 100%;
  padding: 0px 0;
  /* background-color: #fdecec; */
  background-color: #ffffff;
}

.services .container {
  display: flex;
  justify-items: center;
  justify-content: space-evenly;
  gap: 0px;
  grid-template-columns: repeat(2, 1fr);
}

.services .card {
  max-width: 200px;
  height: 100%;
  text-align: center;
  padding-bottom: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 850px) {
  .services .container {
    max-width: 80%;
  }
  .services .card {
    max-width: 350px;
    /* height: 100%; */
    /* max-height: 70vh; */
    margin-left: 60px;
    margin-right: 60px;
  }
  .services img {
    margin-top: 15px;
  }
}

.services a {
  width: auto;
  height: 100%;
}

.services img {
  width: auto;
  /* height: 100%; */
  transition: 0.3s transform;
}

.services img:hover {
  transform: scale(1.1);
}

.services img .clicked {
  opacity: 0.4;
  filter: alpha(opacity=40); /* msie */
}

.services .content {
  text-align: center;
}

.services .content .title {
  font-size: 1rem;
  font-weight: 40;
  margin: 16px 0 10px;
}

.services h2 {
  font-size: 1.5rem;
  font-weight: 80;
  margin: 16px 0 16px;
}

@media screen and (max-width: 750px) {
  .services .container {
    grid-template-columns: 1fr;
  }
}

.generate {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  /* background-color: #fdd9d9; */
  background-color: #ffffff;
}

.generate .title {
  font-size: 2rem;
  font-weight: 400;
  margin: 16px 0 32px;
}

.generate .container {
  gap: 50px;
  font-weight: 400;
  justify-items: center;
  text-align: center;
}

.generate #undress {
  margin: 32px 0 32px;
}

.generate .mask #init_img {
  position: absolute;
  z-index: 2;
  height: 400px;
  width: 250px;
}

.generate .mask {
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  justify-items: center;
  text-align: center;
  display: grid;
}

.generate .mask canvas {
  border: 2px solid black;
  position: relative;
  z-index: 3;
  height: 400px;
  width: 250px;
}

.generate .mask .buttonDiv {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
}

.generate #output {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.generate #result {
  justify-items: center;
  text-align: center;
}

.generate #result img {
  max-width: 350px;
  max-height: 80vh;
}

.generate #result h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

@media screen and (min-width: 750px) {
  .generate #result img {
    max-width: 650px;
  }
}

/* Uploader */
.drag-image {
  border: 1px dashed #000000;
  height: 400px;
  width: 300px;
  border-radius: 5px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drag-image.active {
  border: 2px solid #000000;
}
.drag-image .icon {
  font-size: 30px;
  color: #000000;
}
.drag-image h6 {
  font-size: 20px;
  font-weight: 300;
  color: #000000;
}
.drag-image span {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin: 10px 0 15px 0;
}
.drag-image button {
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 300;
  border: none;
  outline: none;
  background: transparent;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s;
}

.drag-image button:hover {
  background-color: #000000;
  color: red;
}

.drag-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

/* Radio Button */
.wrapper {
  display: inline-flex;
  background: #fff;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}
.wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #74a84a; /* #0069d9; */
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"] {
  display: none;
}

.wrapper .option span {
  font-size: 20px;
  color: #808080;
  margin-left: 6px;
}

.params {
  display: block;
  /* background-color: #fdd9d9; */
  background-color: #fafafa;
  min-height: 100px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 20px 15px;
  /* box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2); */
}

.params h3 {
  color: #808080;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  margin: 32px 32px 32px;
}
.params .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.params .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.params .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #74a84a; /* #0069d9; */
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.params .option span {
  font-size: 20px;
  color: #808080;
}

.infoText {
  font-size: 0.8rem;
  padding-bottom: 10px;
}

.parameters {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
}

.filter-switch {
  margin-inline-end: auto;
  margin-inline-start: auto;
  /* border: 2px solid #ffc000; */
  border: 2px solid #e0abe0;
  border-radius: 30px;
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  height: 50px;
  width: 300px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
.filter-switch input {
  display: none;
}
.filter-switch label {
  flex: 10;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s;
  font-weight: 500;
  font-size: 18px;
}
.filter-switch .bg2,
.filter-switch .bg3 {
  position: absolute;
  width: 49%;
  height: 38px;
  /* background-color: #ffc000; */
  background-color: #e0abe0;
  top: 4px;
  left: 4px;
  border-radius: 30px;
  transition: left 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.filter-switch .bg3 {
  width: 33%;
}
#option2:checked ~ .bg3 {
  left: 33%;
}
#option3:checked ~ .bg3 {
  left: 66%;
}
#option1:checked + label[for="option1"] {
  color: #212121;
  font-weight: bold;
}
#option2:checked + label[for="option2"] {
  color: #212121;
  font-weight: bold;
}
#option3:checked + label[for="option3"] {
  color: #212121;
  font-weight: bold;
}
#option1:not(:checked) + label[for="option1"],
#option2:not(:checked) + label[for="option2"],
#option3:not(:checked) + label[for="option3"] {
  color: #7d7d7d;
}

#womanRadio:checked ~ .bg2 {
  left: 1%;
}
#manRadio:checked ~ .bg2 {
  left: 50%;
}
#womanRadio:checked + label[for="womanRadio"] {
  color: #212121;
  font-weight: bold;
}
#manRadio:checked + label[for="manRadio"] {
  color: #212121;
  font-weight: bold;
}
#womanRadio:not(:checked) + label[for="womanRadio"],
#manRadio:not(:checked) + label[for="manRadio"] {
  color: #7d7d7d;
}

#photoRadio:checked ~ .bg2 {
  left: 1%;
}
#mangaRadio:checked ~ .bg2 {
  left: 50%;
}
#photoRadio:checked + label[for="photoRadio"] {
  color: #212121;
  font-weight: bold;
}
#mangaRadio:checked + label[for="mangaRadio"] {
  color: #212121;
  font-weight: bold;
}
#photoRadio:not(:checked) + label[for="photoRadio"],
#mangaRadio:not(:checked) + label[for="mangaRadio"] {
  color: #7d7d7d;
}

#underwearRadio:checked ~ .bg2 {
  left: 1%;
}
#nudeRadio:checked ~ .bg2 {
  left: 50%;
}
#underwearRadio:checked + label[for="underwearRadio"] {
  color: #212121;
  font-weight: bold;
}
#nudeRadio:checked + label[for="nudeRadio"] {
  color: #212121;
  font-weight: bold;
}
#underwearRadio:not(:checked) + label[for="underwearRadio"],
#nudeRadio:not(:checked) + label[for="nudeRadio"] {
  color: #7d7d7d;
}

#lightRadio:checked ~ .bg2 {
  left: 1%;
}
#strongRadio:checked ~ .bg2 {
  left: 50%;
}
#lightRadio:checked + label[for="lightRadio"] {
  color: #212121;
  font-weight: bold;
}
#strongRadio:checked + label[for="strongRadio"] {
  color: #212121;
  font-weight: bold;
}
#lightRadio:not(:checked) + label[for="lightRadio"],
#strongRadio:not(:checked) + label[for="strongRadio"] {
  color: #7d7d7d;
}

/* 
  Pro Params
*/
#smallBreast:checked ~ .bg3 {
  left: 1%;
}

#autoBreast:checked ~ .bg3 {
  left: 33%;
}
#bigBreast:checked ~ .bg3 {
  left: 66%;
}
#smallBreast:checked + label[for="smallBreast"] {
  color: #212121;
  font-weight: bold;
}
#autoBreast:checked + label[for="autoBreast"] {
  color: #212121;
  font-weight: bold;
}
#bigBreast:checked + label[for="bigBreast"] {
  color: #212121;
  font-weight: bold;
}
#smallBreast:not(:checked) + label[for="smallBreast"],
#autoBreast:not(:checked) + label[for="autoBreast"],
#bigBreast:not(:checked) + label[for="bigBreast"] {
  color: #7d7d7d;
}

#skinnyBody:checked ~ .bg3 {
  left: 1%;
}

#autoBody:checked ~ .bg3 {
  left: 33%;
}
#fatBody:checked ~ .bg3 {
  left: 66%;
}
#skinnyBody:checked + label[for="skinnyBody"] {
  color: #212121;
  font-weight: bold;
}
#autoBody:checked + label[for="autoBody"] {
  color: #212121;
  font-weight: bold;
}
#fatBody:checked + label[for="fatBody"] {
  color: #212121;
  font-weight: bold;
}
#skinnyBody:not(:checked) + label[for="skinnyBody"],
#autoBody:not(:checked) + label[for="autoBody"],
#fatBody:not(:checked) + label[for="fatBody"] {
  color: #7d7d7d;
}

#youngAge:checked ~ .bg3 {
  left: 1%;
}

#autoAge:checked ~ .bg3 {
  left: 33%;
}
#oldAge:checked ~ .bg3 {
  left: 66%;
}
#youngAge:checked + label[for="youngAge"] {
  color: #212121;
  font-weight: bold;
}
#autoAge:checked + label[for="autoAge"] {
  color: #212121;
  font-weight: bold;
}
#oldAge:checked + label[for="oldAge"] {
  color: #212121;
  font-weight: bold;
}
#youngAge:not(:checked) + label[for="youngAge"],
#autoAge:not(:checked) + label[for="autoAge"],
#oldAge:not(:checked) + label[for="oldAge"] {
  color: #7d7d7d;
}

#autoHairy:checked ~ .bg2 {
  left: 1%;
}

#yesHairy:checked ~ .bg2 {
  left: 50%;
}

#autoHairy:checked + label[for="autoHairy"] {
  color: #212121;
  font-weight: bold;
}
#yesHairy:checked + label[for="yesHairy"] {
  color: #212121;
  font-weight: bold;
}

#autoHairy:not(:checked) + label[for="autoHairy"],
#yesHairy:not(:checked) + label[for="yesHairy"] {
  color: #7d7d7d;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4caf50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}

.about {
  padding: 100px 0;
  background-color: #fff3f2;
}

.about .container {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 850px) {
  .about .container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.about .content {
  max-width: 100%;
  margin-left: 32px;
}

.about img {
  width: 530px;
}

.about h3 {
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.about p {
  margin-left: 32px;
}
/* footer style 
----------++---------- */
footer {
  padding: 40px 0;
}
footer .container {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer img {
  width: 200px;
}

footer .end {
  display: flex;
  gap: 32px;
  align-items: center;
}

footer .end .to-top {
  font-size: 2rem;
  color: var(--clr-primary);

  transition-duration: 0.3s;
  transition-property: color transform;
}

footer .end .to-top:is(:focus, :hover) {
  color: var(--clr-primary);
  transform: translateY(-5px);
}

footer a {
  margin-right: 20px;
}

/* code {
} */
